import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from 'containers/PrivateRoute';
import PublicRoute from 'containers/PublicRoute';
import LoginPage from 'containers/LoginPage/Loadable';
import ProfilePage from 'containers/ProfilePage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import WelcomePage from 'containers/WelcomePage/Loadable';
import EventListPage from 'containers/EventListPage/Loadable';
import CreateAccountPage from 'containers/CreateAccountPage/Loadable';
import {
  CREATE_EVENT_PAGE,
  CREATE_ACCOUNT_PAGE,
  LOGIN,
  WELCOME_PAGE,
  EVENT_LIST_PAGE,
  USER_PROFILE,
  EDIT_EVENT_PAGE,
  TERMS_OF_USE,
} from 'routes';
import CreateEventPageWrapper from '../CreateEventPage/CreateEventPageWrapper';
import TermsOfUse from '../TermsOfUse/Loadable';

export default function Routes({ user }) {
  return (
    <Switch>
      <PublicRoute exact path={LOGIN} component={LoginPage} />
      <PrivateRoute exact path={USER_PROFILE} component={ProfilePage} />
      <PrivateRoute exact path={WELCOME_PAGE} component={WelcomePage} />
      <PrivateRoute
        exact
        path={CREATE_EVENT_PAGE}
        component={CreateEventPageWrapper}
      />
      <PrivateRoute
        exact
        path={EDIT_EVENT_PAGE}
        component={CreateEventPageWrapper}
      />
      <PrivateRoute
        exact
        path={CREATE_ACCOUNT_PAGE}
        component={CreateAccountPage}
      />
      <PrivateRoute exact path={TERMS_OF_USE} component={TermsOfUse} />
      <PrivateRoute exact path={EVENT_LIST_PAGE} component={EventListPage} />
      <Route exact path="/">
        <Redirect
          to={
            user?.status === 'started' ||
            user?.status === 'finished' ||
            user?.status === 'review_request'
              ? EVENT_LIST_PAGE
              : WELCOME_PAGE
          }
        />
      </Route>
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
}
