import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';
import { WELCOME_PAGE } from '../../routes';
import { $t } from '../../translations';

const FooterNavigation = () => {
  const links = [
    { id: 1, name: $t('navigation_bottom.sitemap'), link: '' },
    { id: 2, name: $t('navigation_bottom.terms_of_use'), link: '' },
    // {
    //   id: 3,
    //   name: $t('navigation_bottom.privacy_policy'),
    //   link: 'https://lumanity.com/privacy-policy/',
    // },
    { id: 4, name: $t('navigation_bottom.cookie_policy'), link: '' },
  ];

  const Item = ({ item, index }) => (
    <li key={index}>
      <Link
        to={{ pathname: item.link }}
        target={item.link && '_blank'}
        className={styles.link}
        style={{ paddingRight: index === links.length - 1 ? 0 : 12 }}
      >
        {item.name}
      </Link>
    </li>
  );

  return (
    <ul className={styles.navigation}>
      {links.map((item, index) => (
        <Item item={item} index={index} key={item.id} />
      ))}
    </ul>
  );
};

export default FooterNavigation;
